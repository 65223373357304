<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'PdfCard',
  props: {
    pdfInfo: {
      type: Object,
    },
    notRendered: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Set a custom css class to the button
    customClass: String,
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
    },
    // Defines a callback function which can be used to update parent state on change event
    callback2: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction2: String,
    // Defines params for callback function or callback action
    callbackParams2: {
      type: Object,
    },
    to: String,
  },
  methods: {
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
    clickCallback2() {
      if (this.callback2) {
        if (!isObjectEmpty(this.callbackParams2)) {
          this.callback2(this.callbackParams2);
        } else {
          this.callback2();
        }
      }
    },
  },
};
</script>
<template>
  <div class="container-card-pdf">
    <b-row>
      <b-col xl="10" lg="10">
        <div class="headline-bold-16 basic-2"
             style="text-overflow: ellipsis;overflow: hidden;margin-top: 4px">
          <div class="uni-container">
            <unicon name="file-alt" fill="#1694D0"
                    height="24" width="24"/>
          </div>
          {{ pdfInfo.name && pdfInfo.name.length > 50 ?
          (pdfInfo.name.slice(0,50) + '...')
          : pdfInfo.name }}
        </div>
      </b-col>
      <b-col xl="2" lg="2">
        <div style="float: right">
          <div class="d-inline-flex" style="width: 100%">
            <div class="chat-delete-card" v-on:click="clickCallback2">
              <unicon name="arrow-right" fill="#1694D0" style="height: 16px; padding-left: 16px;
              padding-right: 16px"
                      height="18" width="18"/>
            </div>

            <div class="chat-delete-card" v-on:click="clickCallback">
              <unicon name="pen" fill="#4F4F4F" style="height: 16px"
                      height="18" width="18"/>

            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.container-card-pdf{
  background: #FFFFFF;
  border-radius: 12px;
  padding: 12px 8px;
  margin-bottom: 24px;
}
.uni-container{
  padding: 8px;
  background: #1694D01A;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 16px;
}
.chat-delete-card {
  padding: 6px 10px;
  background: #F1F3F6;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  margin-left: 8px;
  height: 40px;
  position: unset;
}

</style>
